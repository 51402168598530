import { FC } from 'react';
import { Global, css } from '@emotion/react';
 
const GlobalStyles: FC = () => (
  <Global
    styles={css`
      @font-face {
      font-family: 'Prstartk';
      font-style: normal;
      font-weight: 700;
      src: local(''), 
        url('/prstartk.ttf') format('truetype');
      }

      * {
        box-sizing: border-box;
        font-family: Prstartk;
      }

      html,
      body {
        font-size: 14px;
        width: 100%;
        height: 100%;
        min-height: 100%;
        padding: 0;
        margin: 0;
      }
    `}
  />
);

export default GlobalStyles;