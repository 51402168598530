import styled from '@emotion/styled';

import GlobalStyles from './GlobalStyles';

const Main = styled.main`
  margin: 20px;
  width: 100%;
  text-align: center;
`;

const PlayButton = styled.button`
  margin-top: 3rem;
  padding: 1rem;
  background: none;
  border: none;
  cursor: pointer;
`;

const Footer = styled.footer`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 60px;
`;

function App() {
  return (
    <Main>
      <h1>EQRIA</h1>
      <PlayButton>Play now</PlayButton>
      <Footer>Copyright &copy; 2022 eqria.com</Footer>
      <GlobalStyles />
    </Main>
  );
}

export default App;
